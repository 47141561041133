* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: "微軟正黑體";
  }
  
  footer,
  header {
    display: block;
  }
  
  ul {
    list-style: none;
  }
  
  
  
  img {
    max-width: 100%;
  }
  
  /*頁首banner*/
  .header_bannerBar.show {
    display: block;
  }
  
  .header_bannerBar {
    display: block;
    position: fixed;
    color: white;
    top: 0;
    left: 0;
    z-index: 21;
    width: 100%;
    background: #7F958E;
    text-align: center;
    padding: 10px 0;
  }
  
  .header_bannerBar.show {
    display: block;
  }
  
  .header_bannerBar:hover {
    color: #EED7B1;
  }
  
  .header_inner {
    position: relative;
  }
  
  header {
    height: 60px;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 1px 1px rgba(202, 232, 239, 0.5);
    background: #fff;
  }
  
  a {
    color: #333;
    text-decoration: none;
  }
  
  a {
    color: #114085;
    cursor: pointer;
    text-decoration: none;
  }
  
  body {
    color: #4a4a4a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  
  /*logo*/
  
  a.logo {
    position: relative;
    top: 50%;
    top: 65px;
    width: 83px;
    height: 60px;
    text-decoration: none;
    text-align: center;
  }
  
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  
  
  /*-------導覽列----------*/
  
  nav {
    position: fixed;
    width: auto;
    display: flex;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
    padding-top: 300px;
  }
  
  .nav_container {
    display: flex;
  
    align-items: center;
    margin-top: 10px auto;
  }
  
  ul {
    /* 取消ul預設的內縮及樣式 */
    border-spacing: 2px;
    margin-left: 5vw;
    margin-top: 150px;
    padding: 0;
    list-style: none;
  }
  
  ul.drop-down-menu {
    border: black 1px solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-items: center;
  
    font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
    font-size: 13px;
    margin-left: 0;
    /* Add this line to remove the margin */
  }
  
  ul.drop-down-menu li {
    white-space: nowrap;
  }
  
  ul.drop-down-menu>li:last-child {
    border-right: none;
  }
  
  ul.drop-down-menu>li {
    float: left;
    /* 只有第一層是靠左對齊*/
  }
  
  ul.drop-down-menu a {
    color: #333;
    display: block;
    padding: 0 30px;
    text-decoration: none;
    line-height: 40px;
  }
  
  ul.drop-down-menu a:hover {
    /* 滑鼠滑入按鈕變色*/
    color: #60a68a;
  }
  
  ul.drop-down-menu li:hover>a {
    /* 滑鼠移入次選單上層按鈕保持變色*/
    color: #60a68a;
  }
  
  ul.drop-down-menu ul {
    border: #ccc 1px solid;
    position: absolute;
    z-index: 99;
    left: -1px;
    top: 100%;
    min-width: 180px;
  }
  
  ul.drop-down-menu ul li {
    border-bottom: #ccc 1px solid;
  }
  
  ul.drop-down-menu ul li:last-child {
    border-bottom: none;
  }
  
  .drop-down-menu ul ul {
    /*第三層以後的選單出現位置與第二層不同*/
    z-index: 999;
    top: 10px;
    left: 90%;
  }
  
  .drop-down-menu ul {
    /*隱藏次選單*/
    display: none;
  }
  
  .drop-down-menu li:hover>ul {
    /* 滑鼠滑入展開次選單*/
    display: block;
  }
  
  /*-------------------*/
  
  /* 舊的CODE */
  /*header ul {
      border: 0.5px solid;
      border-spacing: 2px;
      margin: 10px;
      padding: 5px;
      left: 80px;
      position:fixed;
      top: 150px;;
      line-height:100px;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 12px;
      font-weight:medium;
      font-kerning: normal;
  }
  header li {
      display:inline;
      margin:2vw;
  
  }
  header a {
      color:#474644;
      text-decoration: none;
  }
  */
  .menu {
    display: none;
  }
  
  .div page-loader {
    display: inline;
    top: 500px;
  }
  
  footer {
    font-family: Georgia, "Times New Roman", Times, serif;
    position: absolute;
    color: #60a68a;
    text-decoration: underline#fef9d7;
    height: 100px;
    width: 100%;
    top: 200vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background-image: linear-gradient(to top, #d299c2 0%, #fef9d7 100%);
  }
  
  /* 響應式設計語法 */
  @media screen and (max-width: 768px) {
    header {
      height: 20%;
    }
  
    h1 {
      top: 75px;
      text-indent: 100px;
      background-image: none;
      font-family: serif;
      font-kerning: normal;
      font-weight: bolder;
      text-decoration: underline #ee81d2;
      text-decoration-thickness: 10px;
    }
  
    header ul {
      display: none;
    }
  
    header h1 {
      left: 50%;
      transform: translateX(-50%);
    }
  
    .menu {
      display: flex;
      background-color: transparent;
      color: white;
      font-size: 35px;
      position: absolute;
      top: 15px;
      left: 20px;
      border: none;
      cursor: pointer;
    }
  }